import './App.css';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LinkProvider } from './Context'
import { CircularProgress } from '@material-ui/core';
import Report from './pages/report';
const Index = lazy(() => import("./pages/index"))


function App() {

  return (
    <LinkProvider>
      <Router>
        <Suspense fallback={<div style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}>
          <CircularProgress disableShrink />
          <h1 style={{ color: 'blue' }}>Loading... </h1>

        </div>}>
          <Switch>
            <Route exact path="/" component={Index} />
            <Route path="/report/:term" component={Report} />
          </Switch>
        </Suspense>
      </Router>
    </LinkProvider >
  );
}

export default App;
