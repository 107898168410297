import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Work } from '@material-ui/icons';
import MapState from '../map-state/MapState'
import firebase from "firebase/app";
import "firebase/storage"
import firebaseConfig from "../components/auth/Config"
import axios from 'axios'
import { connect } from 'react-redux'
import { green } from '@material-ui/core/colors';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdbreact';
import "./report.css";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography variant="caption">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: '#e0e0e0',
    },
    headline: {
        textAlign: 'center'
    },
    subtitle: {
        background: green['50'],
        borderRadius: 50,
        display: 'inline-block',
        padding: '5px 10px',
        margin: '10px 0px',
        fontSize: '1.5rem'
    }
}));

function Report({ Tokens }) {
    const [sheets, setSheets] = React.useState([])
    const [data, setData] = React.useState([{}])
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig)
    }

    useEffect(() => {
        if (sheets.length === 0) {
            getSheets()
        }
    }, [sheets])

    function getSheets() {
        axios.get(`https://sheets.googleapis.com/v4/spreadsheets/1N6cm1HuIEJCcpCr9OVJ5w1R_3Jv_q1KwbraOr6C06rk/values/A1%3AH90?key=AIzaSyCny7peaQNCQ5iLtWdVqMKu_qFZppw8afk`, {
            headers: {
                Accept: "application/json"
            }
        })
            .then(res => {
                setSheets(res.data.values.filter((item, index) => index !== 0))
                res.data.values.forEach((item) => {
                    const storage = firebase.storage();
                    var pathReference = storage.ref(`${item[6]}`);
                    pathReference.listAll().then(res => {

                        res.items.filter((item, index) => index !== 0).map(async (itemRef, index) => {
                            const url = await itemRef.getDownloadURL()
                            setData(state => {
                                const newState = state.concat({
                                    index: index,
                                    url: url,
                                    date: item[1],
                                    week: item[0],
                                    subject: item[2]
                                })
                                return newState
                            })
                        })
                    })
                })
            })
            .catch(err => console.log(err))
    }

    const week = [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "15",
        "16",
        "17",
        "18",
    ]

    return (
        <div className={classes.root}>

            <AppBar position="static" className="special-color-dark">
                <MDBContainer>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="secondary"
                        textColor="inherit"
                        aria-label="scrollable force tabs example"
                    >
                        {week.map((item, index) =>
                            <Tab style={{ fontFamily: 'Kanit' }} label={`สัปดาห์ที่ ${item}`} key={index} icon={<Work />} {...a11yProps(index)} />
                        )}
                    </Tabs>
                </MDBContainer>
            </AppBar>
            <MDBContainer>
                <div className="mdb-lightbox no-margin">
                    {week.map((weekItem, index) => (
                        <TabPanel key={index} value={value} index={index}>
                            <MDBCard reverse>
                                <MDBCardBody cascade className="text-center">
                                    <MDBCardTitle style={{ fontSize: '32px', fontFamily: 'Kanit' }}><MDBIcon className="mr-2" icon="heart" />รายงานผลการฝึกสอน</MDBCardTitle>
                                    <h5 className="indigo-text" style={{ fontFamily: 'Kanit', fontSize: '20px' }}><strong>สัปดาห์ที่ {weekItem}</strong></h5>
                                    {sheets.filter(item => item[0] === weekItem).map((item, index) =>
                                        <div key={index} style={{ fontFamily: 'Kanit', fontSize: '16px' }}>
                                            {item[2]}
                                        </div>)
                                    }
                                    <MDBRow>
                                        {data.filter(item => item.week === weekItem).map((item, index) =>
                                            <MDBCol md="4" xs="6" lg="4" sm="6" key={index}>
                                                <figure>
                                                    <img
                                                        src={`${item.url}`} alt={`${item.url}`}
                                                        className="img-fluid"

                                                    />
                                                </figure>
                                            </MDBCol>
                                        )}
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>

                        </TabPanel>
                    ))}
                </div>
            </MDBContainer>
        </div>
    );
}
export default connect(MapState)(Report)