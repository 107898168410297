import React, { useReducer } from 'react';
//import reducer from './reducers/page'
// new
let reducer = (state, action) => {
    switch (action.type) {
        case "selected-menu":
            return { Todos: [...state.Todos, { text: action.text, completed: false }] };
        case "ref-main":
            return { Todos: [...state.Todos, { payload: action.payload }] }
        default: return state;
    }
};
const initialState = { Todos: [] }
const LinkContext = React.createContext(initialState);

function LinkProvider(props) {
    // new
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        // new
        <LinkContext.Provider value={{ state, dispatch }}>
            {props.children}
        </LinkContext.Provider>
    );
}

export { LinkContext, LinkProvider };