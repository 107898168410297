var firebaseConfig = {
    apiKey: "AIzaSyCny7peaQNCQ5iLtWdVqMKu_qFZppw8afk",
    authDomain: "petchdev-49747.firebaseapp.com",
    databaseURL: "https://petchdev-49747.firebaseio.com",
    projectId: "petchdev-49747",
    storageBucket: "petchdev-49747.appspot.com",
    messagingSenderId: "SENDER_ID",
    appId: "APP_ID",
    measurementId: "G-MEASUREMENT_ID",
};

export default firebaseConfig;